nav{
    background-color: #fff;
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 0px;
}
nav a {
    color: rgb(168, 45, 73) !important;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

nav #lastNav{
    border: solid 3px #ab3550 ;
    background-color: #ab3550;
    box-sizing: border-box;
    color: white !important;
}
nav #lastNav:hover{
    background-color: transparent;
    color: #ab3550 !important;
    transition: .2s ease-in-out;
}

nav img{
    max-width: 100%;
    height: 90%;
}