.numbers {
  background-color: #a82d49;
  padding: 25px 0;
  max-width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  color: #fff;
  font-family: "Tajawal";
  margin-top: 0px;
}
.numbers .row {
  margin: 10px 0px;
}
.numbers h2 {
  font-size: 50px;
  font-weight: bold;
  margin: 10px 0;
}
.numbers h6 {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

/* css to the pride, ingredients and paralex section */
.pride,
.ingredients {
  background-color: transparent;
  padding: 25px 0;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  color: #000;
  font-family: "Tajawal";
  margin-top: 60px;
}

.pride img,
.ingredients img {
  max-width: 100%;
  border-radius: 10px;
}
.pride h2,
.ingredients h2,
.paralex h2 {
  font-size: 50px;
  font-weight: bold;
  width: 80%;
  margin: 10px 0;
  font-family: "Tajawal";
  color: #77132b;
  line-height: 50px;
}

.pride p,
.ingredients p {
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-family: "Tajawal";
  margin-top: 15px;
}

.pride button,
.ingredients button {
  background-color: #a82d49;
  border: 1px solid #a82d49;
  box-sizing: border-box;
  width: 140px;
  height: 40px;
  text-align: center;
  transition: 0.2s ease;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.pride button a,
.ingredients button a {
  display: block;
  color: #fff;
  font-family: "Tajawal";
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}
.pride button a:hover,
.ingredients button a:hover {
  color: #77132b;
}
.pride button:hover,
.ingredients button:hover {
  background-color: #fff;
  color: #77132b;
}

/*  css ingredients section */

.ingredients ul li {
  list-style: square;
  font-family: "Tajawal";
  color: #333;
  margin-top: 10px;
}
.ingredients ul {
  padding: 0;
}

/* css paralex section */
.paralex {
  background: url("../../imgs/1pan.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 30px 0;
  text-align: center;
}
.paralex .row {
  margin: 50px 0;
}
.paralex h2 {
  width: 100%;
  margin: 20px;
}
.paralex p {
  margin: 20px 0;
  color: #444;
  line-height: 32px;
}
.paralex a {
  color: #77132b;
  font-size: 20px;
  margin: 20px 0;
  font-family: "Tajawal";
  text-decoration: none !important;
}

/* css blogs section */
.blogs {
  margin-top: 50px;
  padding: 25px 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.blogs .row:first-child {
  text-align: center;
  margin: 25px 0;
}

.blogs h2 {
  font-size: 45px;
  font-weight: bold;
  width: 100%;
  margin: 10px 0;
  font-family: "Tajawal";
  color: #77132b;
  line-height: 50px;
}
.blogs {
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-family: "Tajawal";
  margin-top: 15px;
}

.blogs .box {
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 5px;
}
.blogs .box img {
  width: 100%;
}

.blogs h5 {
  color: #77132b;
  font-family: "Tajawal";
  font-size: 22px;
  margin: 15px 0;
}

.blogs .box span {
  display: block;
  color: #444;
  font-family: "Tajawal";
  font-size: 16px;
  margin: 10px0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.blogs .box h6 {
  color: #a82d49;
  font-size: 22px;
  font-family: "Tajawal";
  font-weight: bold;
}

.blogs button {
  background-color: #740c24;
  border: 1px solid #740c24;
  box-sizing: border-box;
  width: 140px;
  height: 40px;
  text-align: center;
  transition: 0.2s ease;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.blogs button a {
  display: block;
  color: #fff;
  font-family: "Tajawal";
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}
.blogs button a:hover {
  color: transparent;
}
.blogs button:hover {
  color: #740c24;
  background-color: #fff;
}

/* css sliders section */
.slider {
  background-color: #a82d49;
  border: 1px solid #a82d49;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 50px 0;
}
.slider .carousel{
  background-color: #fff;
  margin: 40px 0;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 25x 0;
}

.slider img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 150px;
}
.slider .carousel-caption p {
  color: #212121;
  font-size: 17px;
  line-height: 30px;
}
.slider .carousel-caption span{
  color: #77132b;
  display: block;
  margin: 15px 0;
  text-transform: uppercase;
  font-size: 17px;
}
.row .text{
  color: #f6f6f6;
}
.slider .carousel-indicators  li{
    width: 60px;
    color: red;
}