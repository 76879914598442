header {
  background: url("../../imgs/header.jpg") center no-repeat;
  background-size: 100%;
  width: 100%;
  max-width: 100%;
  text-align: left;
  height: auto;
  overflow: hidden;
  margin-top: 0pz;
}
header .row {
  margin: 60px 0px;
}

header h2 {
  color: #7c0f28;
  font-size: 50px;
  line-height: 60px;
  font-family: "Tajawal", sans-serif;
  font-weight: bold;
  width: 80%;
}

header p {
  color: #333;
  font-family: "Tajawal", sans-serif;
  margin: 15px 0;
}

header button {
  width: 150px;
  height: 40px;
  background-color: #7c0f28;
  border: 1px solid #7c0f28;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  margin: 20px 10px 20px 0;
  font-weight: bold;
  text-transform: uppercase;
}

header button:last-of-type {
  background-color: white;
  color: black;
}
header button:hover {
  transition: 0.2s ease-in-out;
}
header button:first-of-type:hover {
  background-color: #fff;
  color: #ac2947;
}
header button:last-of-type:hover {
  color: #fff;
  background-color: #ac2947;
}
